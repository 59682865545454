import { DropdownMenu } from "@/components/dropdown-menu";
import { Device } from "@/utils/utility";
import {
  Block,
  CheckCircle,
  DeviceRinging,
  MenuKebab,
  Trash,
} from "../../svgs";
import { Badge } from "./badge";

type DeviceStatus = "active" | "deleted" | "blocked";

type DevicesTableProps = {
  devices: Device[];
  updateDeviceStatus: ({
    status,
    deviceId,
  }: {
    deviceId: string;
    status: "active" | "deleted" | "blocked";
  }) => void;
};

interface ActionButtonProps {
  currentStatus: DeviceStatus;
  onStatusUpdate: (newStatus: DeviceStatus) => void;
}

function ActionButton({
  currentStatus,
  onStatusUpdate,
}: Readonly<ActionButtonProps>) {
  const isActive = currentStatus === "active";
  const Icon = isActive ? Block : CheckCircle;
  const label = isActive ? "Bloquear" : "Ativar";
  const newStatus = isActive ? "blocked" : "active";

  return (
    <DropdownMenu.Item
      className="flex cursor-pointer select-none items-center gap-2.5 border px-4 py-2 text-xs font-medium text-neutral-darkest outline-none transition-colors hover:bg-neutral-lighter data-[highlighted]:bg-neutral-lighter data-[disabled]:opacity-30"
      onClick={() => onStatusUpdate(newStatus)}
    >
      <Icon />
      {label}
    </DropdownMenu.Item>
  );
}

export const DevicesTable = ({
  devices,
  updateDeviceStatus,
}: DevicesTableProps) => {
  return (
    <div className="flex w-full items-center justify-center">
      <table className="w-full">
        <tbody>
          {devices?.map((device) => (
            <tr key={device.deviceId} className="p-4">
              <td className="p-4">
                <div className="flex items-center gap-3">
                  <span className="text-lg">
                    <DeviceRinging />
                  </span>
                  <span className="ml-2">{device.deviceAlias}</span>
                </div>
              </td>
              <td>
                <div className="flex items-center justify-start">
                  <Badge type={device.deviceStatus as never} />
                </div>
              </td>
              <td className="text-right">
                <DropdownMenu>
                  <DropdownMenu.Trigger
                    asChild
                    disabled={device.deviceStatus === "deleted"}
                  >
                    <button
                      disabled={device.deviceStatus === "deleted"}
                      type="button"
                      className="cursor-pointer rounded p-2 hover:bg-neutral-lighter disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-30"
                    >
                      <MenuKebab className="h-5 w-5" />
                    </button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className="min-w-32 overflow-hidden rounded-sm border border-neutral-lighter bg-neutral-white shadow-lower"
                      align="end"
                      sideOffset={5}
                    >
                      <ActionButton
                        currentStatus={device.deviceStatus as DeviceStatus}
                        onStatusUpdate={(status) =>
                          updateDeviceStatus({
                            deviceId: device.deviceId,
                            status,
                          })
                        }
                      />{" "}
                      <DropdownMenu.Separator />
                      <DropdownMenu.Item
                        className="flex cursor-pointer select-none gap-2.5 border px-4 py-2 text-xs/md font-medium text-neutral-darkest outline-none data-[highlighted]:bg-neutral-lighter data-[disabled]:opacity-30 [&+&]:border-t-neutral-lighter"
                        onClick={() =>
                          updateDeviceStatus({
                            status: "deleted",
                            deviceId: device.deviceId,
                          })
                        }
                      >
                        <Trash />
                        Excluir
                      </DropdownMenu.Item>
                      <DropdownMenu.Arrow />
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
