import { ReactNode, useState } from "react";
import { Hooks } from "@/utils";
import { Button, Dialog, Drawer, toast } from "@hyperlocal/vital2";
import { useDeleteUser } from "../../../react-query/team-access-list/useDeleteUser";

type DeleteUserModalProps = {
  children?: ReactNode;
  profileId: string;
  isOpen?: boolean;
};

type ModalContentProps = {
  onClose: () => void;
  onDelete: () => void;
  isPending: boolean;
};

const ModalContent = ({ onClose, onDelete, isPending }: ModalContentProps) => (
  <>
    <div>
      Ao excluir o usuário, o mesmo não poderá mais acessar sua conta digital.
      Você confirma a exclusão?
    </div>
    <div className="mt-4 flex gap-2">
      <Button.Root variant="link" className="w-full" onClick={onClose}>
        Cancelar
      </Button.Root>
      <Button.Root
        className="w-full border-none bg-status-error-default outline-none hover:bg-status-error-dark focus:bg-status-error-dark mobile:!mb-2"
        isLoading={isPending}
        onClick={onDelete}
      >
        Excluir
      </Button.Root>
    </div>
  </>
);

export function DeleteUserModal({
  children,
  profileId,
  isOpen,
}: DeleteUserModalProps) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const { mutate: deleteUser, isPending } = useDeleteUser();

  function onOpenChange(open: boolean) {
    setIsModalOpen(open);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleDeleteUser() {
    deleteUser(profileId, {
      onSuccess: () => {
        toast({
          variant: "success",
          title: "Usuário excluído com sucesso",
        });
        handleCloseModal();
      },
    });
  }

  return isMobile ? (
    <Drawer.Root onOpenChange={onOpenChange} open={isModalOpen}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}
      <Drawer.Content onOpenAutoFocus={(event) => event.preventDefault()}>
        <Drawer.Header>
          <Drawer.Title>Excluir</Drawer.Title>
        </Drawer.Header>
        <ModalContent
          onClose={handleCloseModal}
          onDelete={handleDeleteUser}
          isPending={isPending}
        />
      </Drawer.Content>
    </Drawer.Root>
  ) : (
    <Dialog.Root onOpenChange={onOpenChange} open={isModalOpen}>
      {children && <Dialog.Trigger asChild>{children}</Dialog.Trigger>}
      <Dialog.Content className="max-w-[372px]">
        <Dialog.Header>
          <Dialog.Title>Excluir</Dialog.Title>
        </Dialog.Header>
        <ModalContent
          onClose={handleCloseModal}
          onDelete={handleDeleteUser}
          isPending={isPending}
        />
      </Dialog.Content>
    </Dialog.Root>
  );
}
