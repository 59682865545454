import { Hooks } from "@/utils/utility";
import { DesktopView } from "./desktop-view";
import { MobileView } from "./mobile-view";
import { useDeviceManagement } from "./use-device-management";

export function TrustedDevices() {
  const isMobile = Hooks.useMediaQuery("mobile");
  const deviceManagement = useDeviceManagement(isMobile);

  return isMobile ? (
    <MobileView {...deviceManagement} />
  ) : (
    <DesktopView {...deviceManagement} />
  );
}
