import { Suspense } from "react";
import { ErrorFallback } from "@/components/error-fallback";
import {
  List,
  LoadingList,
} from "@/pages/pending-transactions/tabs/history/components/list";
import { Summary } from "@/pages/pending-transactions/tabs/history/components/summary";
import {
  LoadingTotal,
  Total,
} from "@/pages/pending-transactions/tabs/history/components/total";
import { Hooks } from "@/utils";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const classNames = {
  container:
    "mt-[16px] grid mobile:h-full desktop:h-[726px] grid-cols-[684px_auto] gap-[16px] mobile:grid-cols-1 mobile:p-[16px] mobile:overflow-y-hidden mobile:h-auto mobile:flex-1 mobile:m-0",
  content:
    "flex h-full flex-col gap-y-[16px] mobile:overflow-y-hidden mobile:h-auto",
};

export const History = () => {
  const isDesktop = Hooks.useMediaQuery("desktop");
  const [searchParams] = useSearchParams();
  const activeTransactionId = searchParams.get("transaction");

  const shouldShowSummaryMobile = !isDesktop && !!activeTransactionId;

  return (
    <section className={classNames.container}>
      <div
        className={twMerge(
          classNames.content,
          shouldShowSummaryMobile && "hidden",
        )}
      >
        <ErrorFallback>
          <Suspense fallback={<EmptyHistory />}>
            <Total />
            <List />
          </Suspense>
        </ErrorFallback>
      </div>
      <div
        className={twMerge(
          "flex h-full w-full flex-1 items-center rounded-sm bg-white",
          !shouldShowSummaryMobile && "hidden",
          (shouldShowSummaryMobile || isDesktop) && "flex",
        )}
      >
        <Summary />
      </div>
    </section>
  );
};

export const EmptyHistory = () => {
  const isDesktop = Hooks.useMediaQuery("desktop");

  return (
    <section>
      <div className={classNames.content}>
        <LoadingTotal />
        <LoadingList />
      </div>

      {isDesktop && <Summary />}
    </section>
  );
};
