import { registerDeviceUseCase, RegisterDeviceUseCaseRequest } from "@/utils";
import { toast } from "@hyperlocal/vital2";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, UseMutationResult } from "@tanstack/react-query";

async function registerDevice(data: RegisterDeviceUseCaseRequest) {
  const registerDeviceResponse = await registerDeviceUseCase.execute(data);

  if (registerDeviceResponse.isFailure()) {
    throw new Error(
      registerDeviceResponse.value?.errorMessages?.join(", ") ||
        "Não foi possível registrar o dispositivo.",
    );
  }
}

export function useRegisterDevice() {
  return useMutation({
    mutationFn: registerDevice,
    onError: (error) => {
      toast({ variant: "error", title: "Erro", description: error.message });
    },
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Dispositivo registrado com sucesso!",
      });
    },
  });
}
