import { Status } from "@/components/status";
import type { StatusVariant } from "@/components/status";

export type StatusType =
  | "active"
  | "blocked"
  | "deleted"
  | "inactive"
  | "waiting_activation";

export const mappedTypes: Record<StatusType, string> = {
  active: "Ativo",
  blocked: "Bloqueado",
  deleted: "Excluído",
  inactive: "Inativo",
  waiting_activation: "Aguardando ativação",
};

export const statusVariantMap: Record<StatusType, StatusVariant> = {
  active: "success",
  blocked: "error",
  deleted: "error",
  inactive: "warning",
  waiting_activation: "warning",
};

interface BadgeProps {
  type: StatusType;
  className?: string;
}

export function Badge({ type, className }: Readonly<BadgeProps>) {
  const variant = statusVariantMap[type];

  return (
    <Status variant={variant} className={className}>
      {mappedTypes[type]}
    </Status>
  );
}
