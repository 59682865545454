import { ComponentProps, useMemo } from "react";
import { AnticipationConfirmation } from "@/components/dashboard-pay/dashboard-pay-anticipation-confirmation";
import { DashboardPayAnticipationFields } from "@/components/dashboard-pay/dashboard-pay-anticipation-fields";
import { AnticipationData } from "@/services/types";
import { formatCurrency, getVertical } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { format, formatISO, isWithinInterval, parse } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

const dashboardPayAnticipationSchema = z.object({
  minDate: z.string(), // yyyy-mm-dd
  maxDate: z.string(), // yyyy-mm-dd
});

export type DashboardPayAnticipationFormData = z.infer<
  typeof dashboardPayAnticipationSchema
>;

type DashboardPayAnticipationProps = Pick<
  AnticipationData,
  "dateMax" | "dateMin" | "totalGrossAmount" | "receivableAgenda"
> &
  ComponentProps<"form">;

const getDate = (date: string) => {
  const formattedDate = parse(date, "yyyy-MM-dd", new Date());
  formattedDate.setHours(0, 0, 0, 0);
  return formattedDate;
};

export const DashboardPayAnticipation = ({
  dateMin,
  dateMax,
  totalGrossAmount,
  receivableAgenda,
  className,
}: DashboardPayAnticipationProps) => {
  const [DEFAULT_MIN_DATE, DEFAULT_MAX_DATE] = [
    format(dateMin ? new Date(dateMin) : new Date(), "yyyy-MM-dd"),

    format(dateMax ? new Date(dateMax) : new Date(), "yyyy-MM-dd"),
  ];

  const methods = useForm<DashboardPayAnticipationFormData>({
    resolver: zodResolver(dashboardPayAnticipationSchema),
    defaultValues: {
      minDate: DEFAULT_MIN_DATE,
      maxDate: DEFAULT_MAX_DATE,
    },
  });

  const debounceMinDate = methods.watch("minDate");
  const debounceMaxDate = methods.watch("maxDate");

  const vertical = getVertical();

  const anticipation = useMemo(() => {
    return receivableAgenda?.reduce(
      (prev, cur) => {
        const date = new Date(cur.date);
        date.setHours(0, 0, 0, 0);

        if (
          !isWithinInterval(date, {
            end: getDate(debounceMaxDate),
            start: getDate(debounceMinDate),
          })
        )
          return prev;

        cur.receivables.forEach((receivable) => {
          prev.nsuList.push(receivable.instalmentNsu);
          prev.grossValue += receivable.receivableGrossAmount;
          prev.netValue += receivable.receivableAntecipationNetAmount;
          prev.totalFees +=
            receivable.receivableMdr + receivable.receivableAnticipationCost;
        });
        return {
          nsuList: [...new Set(prev.nsuList)],
          netValue: prev.netValue,
          grossValue: prev.grossValue,
          totalFees: prev.totalFees,
        };
      },
      {
        nsuList: [],
        netValue: 0,
        grossValue: 0,
        totalFees: 0,
      },
    );
  }, [debounceMaxDate, debounceMinDate, receivableAgenda]);

  const isDisabled = totalGrossAmount <= 0 || receivableAgenda?.length === 0;

  const onSubmit = (data: DashboardPayAnticipationFormData) => {
    console.log({ data });
  };

  return (
    <FormProvider {...methods}>
      <form
        className={twMerge("flex h-full flex-col justify-between", className)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <section className="flex flex-col gap-[24px]">
          <h2 className="text-sm font-bold text-neutral-darkest">
            Selecione o período que deseja antecipar:
          </h2>

          <div className="flex flex-col items-center gap-2">
            <span className="text-x2s font-medium text-neutral-darkest">
              Valor antecipado
            </span>

            <span className="border-b text-xl font-bold text-neutral-darkest">
              {formatCurrency(anticipation?.grossValue)}
            </span>

            <span className="text-x2s text-neutral-dark">
              Valor disponível: <b>{formatCurrency(totalGrossAmount)}</b>
            </span>
          </div>

          <DashboardPayAnticipationFields
            dateMax={dateMax || formatISO(new Date())}
            dateMin={dateMin || formatISO(new Date())}
            DEFAULT_MAX_DATE={DEFAULT_MAX_DATE}
            DEFAULT_MIN_DATE={DEFAULT_MIN_DATE}
            disabled={isDisabled}
          />
        </section>

        <section className="space-y-4">
          {vertical !== "crossX" && (
            <p className="text-neutral-dark">
              <span className="text-status-warning-dark">Importante:</span> É
              possível apenas antecipar valores transacionais pessoalmente;
              qualquer transação realizada online não será antecipada.
            </p>
          )}

          <div className="space-y-3">
            <div className="grid w-full grid-cols-2 gap-2">
              <span>Desconto e taxas</span>
              <span className="text-right text-status-error-dark">
                {formatCurrency((anticipation?.totalFees || 0) * -1)}
              </span>

              <span className="font-bold">Valor a receber</span>
              <span className="text-right text-sm font-bold">
                {formatCurrency(anticipation?.netValue || 0)}
              </span>
            </div>

            <AnticipationConfirmation nsuList={anticipation?.nsuList} />
          </div>
        </section>
      </form>
    </FormProvider>
  );
};
