import { API, queries, useAccountStore } from "@/utils";
import { toast } from "@hyperlocal/vital2";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const { teamAccessKeys } = queries;

export function useDeleteUser(config = {}) {
  const { currentAccountId } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    mutationFn: async (userId: string) => {
      await API.accessControl.delete("/api/UserManager", {
        params: { userId },
      });
    },
    onMutate: async () => {
      const queryKey = teamAccessKeys.list({
        accountId: currentAccountId,
        deleted: false,
      });

      await queryClient.cancelQueries({ queryKey });
    },
    onError: (error) => {
      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });
        return;
      }

      toast({
        variant: "error",
        title: "Erro ao enviar os dados",
        description: "Tente novamente mais tarde.",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: teamAccessKeys.list({
          accountId: currentAccountId,
          deleted: false,
        }),
      });
    },
  });
}