export type Content = {
  title: string;
  body: string;
  actions: {
    confirm: {
      label: string;
      action: () => void;
    };
    cancel: {
      label: string;
      action: () => void;
    };
  };
};

type CreateContentInput = {
  handleConfirmDeny: (confirmed: boolean) => void;
  handleRegistration: (accepted: boolean) => void;
};

export const createContent = ({
  handleRegistration,
  handleConfirmDeny,
}: CreateContentInput): Record<
  "registrationWarning" | "registrationConfirmation",
  Content
> => ({
  registrationWarning: {
    title: "Dispositivo confiável",
    body: "Este dispositivo não está cadastrado como confiável. Deseja cadastrá-lo?",
    actions: {
      confirm: {
        label: "Sim",
        action: () => handleRegistration(true),
      },
      cancel: {
        label: "Não",
        action: () => handleRegistration(false),
      },
    },
  },
  registrationConfirmation: {
    title: "Tem certeza disso?",
    body: "Os dispositivos que não estiverem previamente autorizados serão limitados a R$ 200 por transação e R$ 1.000 por dia ao efetuar transferências de Pix.",
    actions: {
      confirm: {
        label: "Desejo cadastrar",
        action: () => handleConfirmDeny(false),
      },
      cancel: {
        label: "Não cadastrar",
        action: () => handleConfirmDeny(true),
      },
    },
  },
});
