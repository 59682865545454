import { useState } from "react";
import {
  useInfiniteListDevices,
  useListDevices,
} from "@/api/device-fingerprint/use-list-devices";
import { useUpdateDevice } from "@/api/device-fingerprint/use-update-device";
import { useAccountStore } from "@/utils/utility";

export type DeviceStatus = "active" | "blocked" | "deleted";

interface UpdateStatusParams {
  deviceId: string;
  status: DeviceStatus;
}

const ITEMS_PER_PAGE = "10";

export function useDeviceManagement(isInfiniteList = false) {
  const personId = useAccountStore(
    (state) => state.account.personInfoResponse.personId,
  );
  const [page, setPage] = useState(1);
  const [showDeleted, setShowDeleted] = useState(false);

  const infinite = useInfiniteListDevices({
    itemsPerPage: ITEMS_PER_PAGE,
    status: showDeleted ? "deleted" : undefined,
    enabled: isInfiniteList,
  });

  const paginated = useListDevices({
    page: page.toString(),
    status: showDeleted ? "deleted" : undefined,
    enabled: !isInfiniteList,
  });

  const {
    results: devices,
    isLoading,
    currentPage,
    pageCount,
    refetch,
  } = isInfiniteList
    ? {
        results: infinite.results,
        isLoading: infinite.isLoading,
        currentPage: 1,
        pageCount: 1,
        refetch: infinite.refetch,
      }
    : paginated;

  const updateDeviceMutation = useUpdateDevice({
    page: isInfiniteList ? undefined : page.toString(),
    itemsPerPage: isInfiniteList ? ITEMS_PER_PAGE : null,
    status: showDeleted ? "deleted" : undefined,
  });

  const handleUpdateDeviceStatus = async ({
    deviceId,
    status,
  }: UpdateStatusParams) => {
    try {
      await updateDeviceMutation.mutateAsync({
        deviceStatus: status,
        personId,
        deviceId,
      });
      await refetch();
    } catch (error) {
      console.error("Failed to update device status:", error);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const toggleDeletedStatus = () => setShowDeleted((prev) => !prev);

  const handleResetFilters = () => {
    setPage(1);
    setShowDeleted(false);
  };

  return {
    devices,
    isLoading,
    currentPage,
    pageCount,
    showDeleted,
    handleUpdateDeviceStatus,
    handlePageChange,
    toggleDeletedStatus,
    handleResetFilters,
    hasNextPage: infinite.hasNextPage,
    fetchNextPage: infinite.fetchNextPage,
    isFetchingNextPage: infinite.isFetching,
  };
}
