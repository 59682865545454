import { useMemo, useState } from "react";
import { useGetReceivables } from "@/api/anticipation/use-get-receivables";
import { DashboardPayAnticipation } from "@/components/dashboard-pay/dashboard-pay-anticipation";
import { formatCurrency, getDashboardRoutesData } from "@/utils";
import { Button, Sheet } from "@hyperlocal/vital2";
import { useNavigate } from "react-router-dom";

export const DashboardPayTotalAmount = () => {
  const [openSheet, setOpenSheet] = useState(false);

  const { data, isLoading } = useGetReceivables();

  const navigate = useNavigate();
  const routes = getDashboardRoutesData();

  const content = useMemo(() => {
    if (isLoading || !data) {
      return (
        <div className="h-[2ex] w-[5ch] animate-pulse rounded-lg bg-neutral-lighter" />
      );
    }

    return formatCurrency(data.totalGrossAmount).replace("R$", "");
  }, [data, isLoading]);

  return (
    <>
      {/* Desktop */}
      <div className="flex justify-between mobile:!hidden">
        <div className="flex flex-col justify-between gap-stack-quarck">
          <span className="text-x2s">Valor a receber</span>

          <div className="flex items-center text-md font-bold text-primary-main">
            <span className="mr-inline-x2s text-xs">R$</span>
            {content}
          </div>
        </div>

        <Button.Root
          variant="link"
          disabled={isLoading}
          onClick={() => setOpenSheet(true)}
        >
          Antecipar
        </Button.Root>

        <Sheet.Root open={openSheet} onOpenChange={setOpenSheet}>
          <Sheet.Content side="right" className="px-[16px] py-[32px]">
            <Sheet.Header>
              <Sheet.Title className="text-md text-neutral-darkest">
                Antecipar
              </Sheet.Title>
            </Sheet.Header>

            <DashboardPayAnticipation {...data} />
          </Sheet.Content>
        </Sheet.Root>
      </div>

      {/* Mobile */}
      <div className="hidden flex-col items-center justify-center gap-1 mobile:!flex">
        <p className="text-x2s text-neutral-darkest">Valor a receber</p>
        <b className="flex gap-2 text-lg font-bold text-primary-main">
          R$ {content}
        </b>

        <Button.Root
          variant="link"
          disabled={isLoading}
          onClick={() => navigate(routes?.dashboardPayAnticipation)}
        >
          Antecipar
        </Button.Root>
      </div>
    </>
  );
};
