import { Device } from "@/utils/utility";
import { DeviceRinging } from "../../svgs";
import { Badge } from "./badge";
import { DeviceActions } from "./device-actions";
import { DeviceStatus } from "./use-device-management";

interface DeviceItemProps {
  device: Device;
  onUpdateStatus: (params: {
    deviceId: string;
    status: DeviceStatus;
  }) => Promise<void>;
}

export function DeviceItem({
  device,
  onUpdateStatus,
}: Readonly<DeviceItemProps>) {
  return (
    <div className="flex items-center justify-between p-4 shadow-lower">
      <div className="flex items-center gap-3">
        <DeviceRinging className="text-lg" />
        <div className="flex flex-col gap-2">
          <span>{device.deviceAlias}</span>
          <Badge type={device.deviceStatus} />
        </div>
      </div>
      <DeviceActions device={device} onUpdateStatus={onUpdateStatus} />
    </div>
  );
}
