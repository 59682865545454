export const DeviceSkeleton = () => (
  <tr className="animate-pulse">
    <td className="p-4">
      <div className="flex items-center gap-3">
        <div className="h-6 w-6 rounded-full bg-neutral-lighter" />
        <div className="h-4 w-48 rounded bg-neutral-lighter" />
      </div>
    </td>
    <td>
      <div className="h-4 w-20 rounded bg-neutral-lighter" />
    </td>
    <td className="text-right">
      <div className="ml-auto h-8 w-8 rounded bg-neutral-lighter" />
    </td>
  </tr>
);

export const LoadingSkeleton = () => (
  <div className="flex w-full items-center justify-center">
    <table className="w-full">
      <tbody>
        {[...Array(3)].map((_, index) => (
          <DeviceSkeleton key={index} />
        ))}
      </tbody>
    </table>
  </div>
);

export const MobileLoadingSkeleton = () => {
  const items = Array.from({ length: 5 });

  return (
    <div className="flex flex-col gap-4">
      {items.map((_, index) => (
        <LoadingItem key={index} />
      ))}
    </div>
  );
};

export const LoadingItem = () => (
  <div className="flex animate-pulse items-center justify-between border-b p-4">
    <div className="flex items-center gap-3">
      <div className="h-6 w-6 rounded-full bg-neutral-lighter" />
      <div className="flex flex-col gap-2">
        <div className="h-4 w-32 rounded bg-neutral-lighter" />
        <div className="h-5 w-16 rounded bg-neutral-lighter" />
      </div>
    </div>
    <div className="h-8 w-8 rounded bg-neutral-lighter" />
  </div>
);
