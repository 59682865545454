import {
  AccessGroupName,
  accountsQueryKeyFactory,
  API,
  configureAuth,
  confirmSignIn,
  deviceQueryFactory,
  EmptyState,
  fetchAuthSession,
  formatCurrency,
  GetAccountInfoUseCaseRequest,
  getAccountUseCase,
  getDashboardRoutesData,
  getReceiptRoutesData,
  getReceivedRoutesData,
  getVertical,
  getVerticalAccountId,
  getVerticalBankName,
  globalQueryClient,
  GlobalQueryClientProvider,
  hasPermission,
  Hooks,
  IdentityConfirmationDialog,
  isAuthenticated,
  listAccountsQueryConfig,
  listAccountsSuspenseQueryConfig,
  listAccountsUseCase,
  Notifications,
  prefetchAccountInfo,
  ProtectedComponent,
  ProtectedRoute,
  queries,
  QueryConfig,
  registerDeviceUseCase,
  setHeader,
  setUpTOTP,
  signIn,
  signOut,
  storage,
  SuspenseQueryConfig,
  themeProvider,
  toast,
  Types,
  updateDevice,
  updateMFAPreference,
  useAccountStore,
  useDeviceFingerprint,
  UseListAccounts,
  User,
  useStateMachine,
  UseSuspenseListAccounts,
  useTrustedDeviceStore,
  useUserStore,
  useVerticalStore,
  validateIdentity,
  verifyDevice,
  verifyTOTPSetup,
  Vertical,
  type Account,
  type Device,
  type DeviceStatusType,
  type ListDeviceUseCaseResponse,
  type ListDeviceUseCaseSuccessResponse,
  type RegisterDeviceUseCaseRequest,
  type UpdateDeviceUseCaseErrorResponse,
  type UpdateDeviceUseCaseRequest,
  type UpdateDeviceUseCaseResponse,
  type VerifyDeviceUseCaseRequest,
  type VerifyDeviceUseCaseResponse,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";

const routes = getReceiptRoutesData();

export {
  useTrustedDeviceStore,
  API,
  AccessGroupName,
  Account,
  EmptyState,
  GetAccountInfoUseCaseRequest,
  GlobalQueryClientProvider,
  Hooks,
  IdentityConfirmationDialog,
  Notifications,
  ProtectedComponent,
  ProtectedRoute,
  QueryConfig,
  RegisterDeviceUseCaseRequest,
  SuspenseQueryConfig,
  Types,
  UseListAccounts,
  UseSuspenseListAccounts,
  User,
  VerifyDeviceUseCaseRequest,
  VerifyDeviceUseCaseResponse,
  Vertical,
  accountsQueryKeyFactory,
  configureAuth,
  confirmSignIn,
  deviceQueryFactory,
  fetchAuthSession,
  formatCurrency,
  getAccountUseCase,
  getDashboardRoutesData,
  getReceivedRoutesData,
  getVertical,
  getVerticalAccountId,
  getVerticalBankName,
  globalQueryClient,
  hasPermission,
  isAuthenticated,
  listAccountsQueryConfig,
  listAccountsSuspenseQueryConfig,
  listAccountsUseCase,
  prefetchAccountInfo,
  queries,
  registerDeviceUseCase,
  routes,
  setHeader,
  setUpTOTP,
  signIn,
  signOut,
  storage,
  themeProvider,
  toast,
  updateDevice,
  updateMFAPreference,
  useAccountStore,
  useDeviceFingerprint,
  useStateMachine,
  useUserStore,
  useVerticalStore,
  validateIdentity,
  verifyDevice,
  verifyTOTPSetup,
  type Device,
  type ListDeviceUseCaseResponse,
  type ListDeviceUseCaseSuccessResponse,
  type UpdateDeviceUseCaseErrorResponse,
  type UpdateDeviceUseCaseRequest,
  type UpdateDeviceUseCaseResponse,
  type DeviceStatusType,
};
