import { type PropsWithChildren } from "react";
import { tv, type VariantProps } from "tailwind-variants";

export type StatusVariant = "success" | "error" | "warning";

const status = tv({
  base: [
    "flex h-7 p-2 w-fit",
    "items-center justify-center",
    "rounded border",
    "text-base font-medium leading-none",
    "font-base",
  ],
  variants: {
    variant: {
      success:
        "border-status-success-dark bg-status-success-light text-status-success-dark",
      error:
        "border-status-error-dark bg-status-error-light text-status-error-dark",
      warning:
        "border-status-warning-dark bg-status-warning-light text-status-warning-dark",
    },
  },
  defaultVariants: {
    variant: "success",
  },
});

interface StatusProps extends VariantProps<typeof status> {
  variant: StatusVariant;
  className?: string;
}

export const Status = ({
  variant,
  children,
  className,
}: PropsWithChildren<StatusProps>) => (
  <div className={status({ variant, className })}>{children}</div>
);
