import { Button, Drawer } from "@hyperlocal/vital2";
import { Content } from "./utils";

type TrustedDeviceDrawerProps = {
  isOpen: boolean;
  onClose?: () => void;
  content: Content;
};

export const TrustedDeviceDrawer = ({
  isOpen,
  content,
}: TrustedDeviceDrawerProps) => (
  <Drawer.Root open={isOpen}>
    <Drawer.Content className="flex flex-col gap-4">
      <Drawer.Header>
        <Drawer.Title>{content.title}</Drawer.Title>
      </Drawer.Header>

      <div className="text-center">{content.body}</div>

      <div className="flex gap-4">
        <Button.Root
          variant="secondary"
          type="button"
          className="w-full"
          onClick={() => {
            content.actions.cancel.action();
          }}
        >
          {content.actions.cancel.label}
        </Button.Root>
        <Button.Root
          type="button"
          variant="primary"
          className="w-full"
          onClick={() => {
            content.actions.confirm.action();
          }}
        >
          {content.actions.confirm.label}
        </Button.Root>
      </div>
    </Drawer.Content>
  </Drawer.Root>
);
