import { globalQueryClient } from "@/utils/utility";
import {
  UseMutationOptions,
  UseQueryOptions,
  type QueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

export const queryClient = globalQueryClient;

export type RequestError = AxiosError<unknown>;

export type QueryConfig<FetcherFnType extends (...args: unknown[]) => unknown> =
  UseQueryOptions<Awaited<ReturnType<FetcherFnType>>>;

export type MutationConfig<
  FetcherFnType extends (...args: unknown[]) => unknown,
> = UseMutationOptions<
  Awaited<ReturnType<FetcherFnType>>,
  RequestError,
  Parameters<FetcherFnType>[0]
>;
