import {
  deviceQueryFactory,
  DeviceStatusType,
  globalQueryClient,
  ListDeviceUseCaseSuccessResponse,
  updateDevice,
  UpdateDeviceUseCaseErrorResponse,
  UpdateDeviceUseCaseRequest,
  UpdateDeviceUseCaseResponse,
} from "@/utils/utility";
import { toast } from "@hyperlocal/vital2";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

type UseUpdateDeviceInput = {
  page: string;
  itemsPerPage: string;
  status?: DeviceStatusType;
};

type UpdateDeviceVariables = UpdateDeviceUseCaseRequest;

type MutationContext = {
  previousData: ListDeviceUseCaseSuccessResponse;
  queryKey: readonly unknown[];
};

type UseUpdateDeviceOutput = UseMutationResult<
  UpdateDeviceUseCaseResponse,
  Error,
  UpdateDeviceVariables,
  MutationContext
>;

function isErrorResponse(
  response: UpdateDeviceUseCaseResponse,
): response is { success: false; error: UpdateDeviceUseCaseErrorResponse } {
  return !response.success;
}

export function useUpdateDevice({
  page,
  itemsPerPage,
  status,
}: UseUpdateDeviceInput): UseUpdateDeviceOutput {
  return useMutation({
    mutationFn: updateDevice,
    mutationKey: ["device-update"],
    async onMutate(variables) {
      const queryKey = deviceQueryFactory.list({
        personId: variables.personId,
        page,
        itemsPerPage,
        status,
      }).queryKey;

      await globalQueryClient.cancelQueries({
        queryKey,
      });

      const previousData =
        globalQueryClient.getQueryData<ListDeviceUseCaseSuccessResponse>(
          queryKey,
        );
      if (previousData?.results) {
        const updateDevice = {
          ...previousData,
          results: previousData.results.map((device) =>
            device.deviceId === variables.deviceId
              ? { ...device, deviceStatus: variables.deviceStatus }
              : device,
          ),
        };
        globalQueryClient.setQueryData(queryKey, updateDevice);
      }

      return { previousData, queryKey };
    },
    onError: (error, _, context) => {
      toast({
        title: "Erro ao atualizar dispositivo",
        variant: "error",
        description: error.message,
      });
      if (context?.previousData) {
        globalQueryClient.setQueryData(context.queryKey, context.previousData);
      }
    },
    onSuccess: (data, variables, context) => {
      if (isErrorResponse(data)) {
        if (context?.previousData) {
          globalQueryClient.setQueryData(
            context.queryKey,
            context.previousData,
          );
        }
        toast({
          title: "Erro ao atualizar dispositivo",
          variant: "error",
          description: data.error?.errorMessages?.join(", ") || "",
        });
        return;
      }
      toast({
        variant: "success",
        title: "Dispositivo atualizado.",
      });
      // const queryKey = deviceQueryFactory.list({
      //   personId: variables.personId,
      //   page,
      //   itemsPerPage,
      // }).queryKey;

      globalQueryClient.invalidateQueries({
        queryKey: deviceQueryFactory.default,
      });
    },
  });
}
