import { deviceQueryFactory } from "@/utils";
import { useQuery } from "@tanstack/react-query";

type UseVerifyDeviceInput = {
  deviceId: string;
  enableQuery: boolean;
};

export function useVerifyDeviceQuery({
  enableQuery,
  deviceId,
}: UseVerifyDeviceInput) {
  const query = useQuery({
    ...deviceQueryFactory.verify({ deviceId }),
    enabled: enableQuery && !!deviceId,
  });
  return {
    isLoading: query.isLoading,
    isError: query.isError,
  };
}
