import type { ComponentProps } from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { twMerge } from "tailwind-merge";

const Root = DropdownMenuPrimitive.Root;
const Portal = DropdownMenuPrimitive.Portal;
const Separator = DropdownMenuPrimitive.Separator;

function DropDownMenuArrow({
  className,
  ...props
}: ComponentProps<typeof DropdownMenuPrimitive.Arrow>) {
  return (
    <DropdownMenuPrimitive.Arrow
      className={twMerge(
        "fill-neutral-white stroke-neutral-lighter",
        className,
      )}
      {...props}
    />
  );
}

function Trigger({
  className,
  ...props
}: ComponentProps<typeof DropdownMenuPrimitive.Trigger>) {
  return (
    <DropdownMenuPrimitive.Trigger
      className={twMerge(
        "cursor-pointer border-none bg-transparent focus:outline-none",
        className,
      )}
      {...props}
    />
  );
}

function Content({
  className,
  ...rest
}: ComponentProps<typeof DropdownMenuPrimitive.Content>) {
  return (
    <DropdownMenuPrimitive.Content
      className={twMerge(
        "data-[side=bottom]:animate-slide-up-and-fade data-[side=top]:animate-slide-down-and-fade inline-flex h-full w-full flex-col rounded-sm border border-neutral-lighter bg-neutral-white",
        className,
      )}
      {...rest}
    />
  );
}

function Item({
  className,
  ...rest
}: ComponentProps<typeof DropdownMenuPrimitive.Item>) {
  return (
    <DropdownMenuPrimitive.Item
      className={twMerge(
        "flex h-10 w-full cursor-pointer items-center gap-inset-xs border-none bg-neutral-white p-4 hover:bg-neutral-lighter hover:outline-none",
        className,
      )}
      {...rest}
    />
  );
}

export const DropdownMenu = Object.assign(Root, {
  Portal,
  Separator,
  Arrow: DropDownMenuArrow,
  Trigger,
  Content,
  Item,
});
