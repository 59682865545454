import { Button, Sheet, TextField } from "@hyperlocal/vital2";

type DeviceAliasSheetProps = {
  isOpen: boolean;
  onClose: () => void;
  errorMessage: string;
  onSubmitDeviceName: (deviceName: string) => void;
};

export const DeviceAliasSheet = ({
  isOpen,
  onClose,
  onSubmitDeviceName,
  errorMessage,
}: DeviceAliasSheetProps) => (
  <Sheet.Root open={isOpen} onOpenChange={onClose}>
    <Sheet.Content className="p-0">
      <Sheet.Header>
        <Sheet.Title className="text-md font-bold text-neutral-darkest">
          Cadastro de dispositivo
        </Sheet.Title>
      </Sheet.Header>
      <div className="flex-1 overflow-y-auto p-2">
        <form
          id="register-device-name"
          className="flex flex-col gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const name = formData.get("deviceName");
            onSubmitDeviceName(name as string);
          }}
        >
          <TextField.Label
            htmlFor="deviceName"
            className="font-bold text-neutral-darkest"
          >
            Digite o nome do dispositivo
          </TextField.Label>
          <TextField.Input
            status={errorMessage ? "error" : undefined}
            id="deviceName"
            name="deviceName"
            placeholder="Digite aqui..."
          />
          <TextField.Helper>{errorMessage}</TextField.Helper>
        </form>
      </div>
      <div className="border-t p-4">
        <Button.Root
          type="submit"
          form="register-device-name"
          className="w-full"
        >
          Cadastrar
        </Button.Root>
      </div>
    </Sheet.Content>
  </Sheet.Root>
);
