import { Button } from "@hyperlocal/vital2";
import magnifyingGlass from "../../../../assets/lupinha.png";

export type EmptyFallbackProps = {
  hasFilter: boolean;
  resetFilters: () => void;
};

export const EmptyFallback = ({
  resetFilters,
  hasFilter,
}: EmptyFallbackProps) => (
  <div className="flex min-h-[200px] w-full flex-1 flex-col items-center justify-center gap-4 py-8">
    <img src={magnifyingGlass} alt="Lupa triste" className="mx-auto" />
    {hasFilter ? (
      <>
        <span className="text-center text-sm text-neutral-main">
          Nenhum dispositivo encontrado com os filtros selecionados
        </span>
        <Button.Root variant="link" onClick={resetFilters}>
          Remover filtros
        </Button.Root>
      </>
    ) : (
      <div className="text-center">
        <span className="block text-sm font-medium text-neutral-darker">
          Nenhum dispositivo registrado
        </span>
        <span className="mt-1 block text-sm text-neutral-main">
          Adicione um novo dispositivo para começar
        </span>
      </div>
    )}
  </div>
);
