import { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "@/components/breadcrumb";
import { ErrorFallback } from "@/components/error-fallback";
import { queryClient } from "@/lib";
import { mappedRole } from "@/pages/settings/sections/team-access/components/change-user-sheet";
import {
  NewUserForm,
  newUserSchema,
  NewUserSchemaInput,
} from "@/pages/settings/sections/team-access/new-access/components/new-user-form";
import { UserPermissionsList } from "@/pages/settings/sections/team-access/new-access/components/user-permissions-list";
import { TabParam } from "@/pages/settings/settings";
import * as Svg from "@/pages/settings/svgs";
import { queries, useAccountStore } from "@/utils";
import {
  API,
  getDashboardRoutesData,
  IdentityConfirmationDialog,
  setHeader,
  validateIdentity,
} from "@/utils/utility";
import { zodResolver } from "@hookform/resolvers/zod";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Button, toast } from "@hyperlocal/vital2";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

const { teamAccessKeys } = queries;

const routes = getDashboardRoutesData();

const breadcrumbs: BreadcrumbItem<"tab", TabParam>[] = [
  {
    name: "Configurações",
    path: "settings",
    searchParam: "?tab=acesso-da-equipe",
  },
  { name: "Novo acesso", path: "settingsNewAccess", active: true },
];

export function NewAccess() {
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [isValidatingIdentity, setIsValidatingIdentity] = useState(false);
  const [isIdentityValidationDialogOpen, setIsIdentityValidationDialogOpen] =
    useState(false);

  const isMobile = Hooks.useMediaQuery("mobile");

  const { currentAccountId } = useAccountStore();
  const navigate = useNavigate();
  const methods = useForm<NewUserSchemaInput>({
    resolver: zodResolver(newUserSchema),
    mode: "onTouched",
  });

  const backRoute = routes.settings + "?tab=acesso-da-equipe";

  async function handleFormSubmit(data: NewUserSchemaInput) {
    try {
      setIsSendingForm(true);

      const formData = data;

      await API.accessControl.post("/api/UserManager", {
        accessId: mappedRole[formData.role],
        cpf: formData.cpf,
        email: formData.email,
        name: formData.name,
        contact: formData.phone,
      });

      await queryClient.invalidateQueries({
        queryKey: teamAccessKeys.list({
          accountId: currentAccountId,
          deleted: false,
        }),
      });

      toast({
        variant: "success",
        title: "Acesso cadastrado",
      });
      navigate(backRoute);
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao cadastrar usuário",
        description:
          error.response.data?.errorMessages?.[0] ??
          "Tente novamente mais tarde.",
      });
    } finally {
      setIsSendingForm(false);
    }
  }

  const values = methods.watch();
  const hasErrors = !newUserSchema.safeParse(values).success;

  const onSubmit = async (data: NewUserSchemaInput) => {
    setIsValidatingIdentity(true);
    await validateIdentity({
      onSuccess: () => {
        handleFormSubmit(data);
        setIsValidatingIdentity(false);
      },
      onFailure: (errorMessage) => {
        setIsValidatingIdentity(false);
        if (errorMessage === "Resource not found") {
          setIsIdentityValidationDialogOpen(true);
        } else {
          toast({
            title: "Erro",
            description: errorMessage,
            variant: "error",
            position: "top-right",
          });
        }
        console.error("errorMessage", errorMessage);
      },
    });
  };

  useEffect(() => {
    if (isMobile) setHeader({});
  }, [isMobile]);

  return (
    <div className="h-full overflow-y-auto bg-neutral-lighter font-base mobile:!bg-neutral-white">
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="mx-auto flex h-full max-w-[1248px] flex-col overflow-hidden p-8 mobile:!mx-0 mobile:!p-0"
      >
        <header>
          <Breadcrumb breadcrumbs={breadcrumbs} />

          <div className="mt-4 flex items-center justify-between mobile:!mt-0">
            <Link
              className="flex w-fit items-center gap-2 py-2 mobile:!m-4 mobile:!mb-2 mobile:!mt-0 mobile:!w-full mobile:!justify-between mobile:!pt-0"
              to={backRoute}
            >
              <Svg.ArrowLeft className="mobile:!hidden" />
              <h3 className="text-lg/default font-bold text-neutral-darkest mobile:!text-sm">
                Novo acesso
              </h3>
              <Svg.Close className="hidden mobile:!block" />
            </Link>

            <div className="flex gap-4 mobile:!hidden">
              <Button.Root
                type="button"
                variant="link"
                onClick={() => navigate(backRoute)}
              >
                Cancelar
              </Button.Root>
              <Button.Root
                data-testid="register-button"
                disabled={hasErrors}
                isLoading={isSendingForm || isValidatingIdentity}
              >
                Cadastrar
              </Button.Root>
            </div>
          </div>
        </header>

        <section className="mt-4 grid flex-1 grid-cols-2 gap-6 overflow-hidden rounded-sm bg-neutral-white p-6 mobile:!mt-0 mobile:!flex mobile:!flex-col mobile:!overflow-auto mobile:!px-4 mobile:!pb-4 mobile:!pt-2 desktop:max-h-[800px]">
          <ErrorFallback>
            <FormProvider {...methods}>
              <NewUserForm />
              {values.role && <UserPermissionsList role={values.role} />}

              <div className="mt-auto hidden gap-4 mobile:!flex">
                <Button.Root
                  type="button"
                  variant="link"
                  className="w-full"
                  onClick={() => navigate(backRoute)}
                >
                  Cancelar
                </Button.Root>
                <Button.Root
                  disabled={hasErrors}
                  isLoading={isSendingForm || isValidatingIdentity}
                  className="w-full"
                >
                  Cadastrar
                </Button.Root>
              </div>
            </FormProvider>
          </ErrorFallback>
        </section>
      </form>
      <IdentityConfirmationDialog
        isOpen={isIdentityValidationDialogOpen}
        onClose={setIsIdentityValidationDialogOpen}
      />
    </div>
  );
}
