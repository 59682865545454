import { MFAValidation } from "@/pages/mfa-validation";
import { Sheet } from "@hyperlocal/vital2";

type MFASheetProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmitToken: (token: string) => void;
  isLoading?: boolean;
};

export const MFASheet = ({
  isOpen,
  isLoading,
  onClose,
  onSubmitToken,
}: MFASheetProps) => {
  return (
    <Sheet.Root open={isOpen} onOpenChange={onClose}>
      <Sheet.Content className="p-0">
        <MFAValidation onSuccess={onSubmitToken} isLoading={isLoading} />
      </Sheet.Content>
    </Sheet.Root>
  );
};
