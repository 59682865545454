import { useRegisterDevice } from "@/api/device-fingerprint";
import {
  createContent,
  DeviceAliasSheet,
  MFASheet,
  TrustedDeviceDrawer,
  TrustedDeviceModal,
} from "@/components/trusted-devices";
import { Hooks } from "@/utils/utility";
import { useDeviceRegistrationFlow } from "./use-device-registration-flow";

export function TrustedDeviceWarning() {
  const isMobile = Hooks.useMediaQuery("mobile");

  const {
    step,
    error,
    isRegistering,
    handleRegistration,
    handleConfirmDeny,
    handleSetAlias,
    handleMfaSubmit,
    reset: handleClose,
  } = useDeviceRegistrationFlow();

  const content = createContent({
    handleConfirmDeny,
    handleRegistration,
  });

  return (
    <>
      {isMobile ? (
        <>
          <TrustedDeviceDrawer
            onClose={handleClose}
            isOpen={step === "register"}
            content={content.registrationWarning}
          />
          <TrustedDeviceDrawer
            onClose={handleClose}
            isOpen={step === "confirm_deny"}
            content={content.registrationConfirmation}
          />
        </>
      ) : (
        <>
          <TrustedDeviceModal
            onClose={handleClose}
            isOpen={step === "register"}
            content={content.registrationWarning}
          />
          <TrustedDeviceModal
            onClose={handleClose}
            isOpen={step === "confirm_deny"}
            content={content.registrationConfirmation}
          />
        </>
      )}
      <DeviceAliasSheet
        errorMessage={error}
        onSubmitDeviceName={handleSetAlias}
        isOpen={step === "set_alias"}
        onClose={handleClose}
      />
      <MFASheet
        isLoading={isRegistering}
        onSubmitToken={(token) => handleMfaSubmit(token)}
        isOpen={step === "set_mfa"}
        onClose={handleClose}
      />
    </>
  );
}
