import { useEffect } from "react";
import { ScrollArea } from "@/components/scroll-area";
import { Device } from "@/utils/utility";
import { Chips } from "@hyperlocal/vital";
import { useInView } from "react-intersection-observer";
import { DeviceItem } from "./device-item";
import { EmptyFallback } from "./empty-fallback";
import { LoadingItem, MobileLoadingSkeleton } from "./loading-skeleton";
import { DeviceStatus } from "./use-device-management";

interface MobileViewProps {
  devices: Device[];
  isLoading: boolean;
  showDeleted: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
  handleUpdateDeviceStatus: (params: {
    deviceId: string;
    status: DeviceStatus;
  }) => Promise<void>;
  handleResetFilters: () => void;
  toggleDeletedStatus: () => void;
}

export function MobileView({
  devices,
  isLoading,
  showDeleted,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  handleUpdateDeviceStatus,
  handleResetFilters,
  toggleDeletedStatus,
}: MobileViewProps) {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && fetchNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, isFetchingNextPage]);

  return (
    <div className="flex h-full flex-col">
      <Chips
        active={showDeleted}
        className="mb-4 w-fit"
        onClick={toggleDeletedStatus}
      >
        Visualizar dispositivos excluídos
      </Chips>

      {isLoading && <MobileLoadingSkeleton />}

      {!isLoading && !devices?.length && (
        <EmptyFallback
          hasFilter={showDeleted}
          resetFilters={handleResetFilters}
        />
      )}

      {!isLoading && devices?.length > 0 && (
        <ScrollArea className="h-[calc(100vh-200px)]">
          {devices.map((device) => (
            <DeviceItem
              key={device.deviceId}
              device={device}
              onUpdateStatus={handleUpdateDeviceStatus}
            />
          ))}
          {hasNextPage && (
            <div ref={ref}>
              {isFetchingNextPage ? <LoadingItem /> : <div className="h-10" />}
            </div>
          )}
        </ScrollArea>
      )}
    </div>
  );
}
