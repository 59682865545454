import { useListAccounts } from "@/api/account-management";
import { DashboardContainer } from "@/components/dashboard-container";
import { Chart, Fallback, Header, Loading } from "@/pages/dashboard/components";
import { WarningToast } from "@/pages/dashboard/components/warning";
import { ProtectedComponent } from "@/utils";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { TrustedDeviceWarning } from "./components";

export function Dashboard() {
  const { isLoading: isLoadingAccounts } = useListAccounts();

  const userExists = true;
  const userHasAccess = true;

  if (isLoadingAccounts) return <Loading />;

  if (!userExists || !userHasAccess) {
    return <Fallback userExists={userExists} />;
  }

  return (
    <div className="h-full overflow-y-auto bg-neutral-lighter font-base mobile:!max-h-[100svh] mobile:!bg-neutral-white">
      <DashboardContainer>
        <Header />

        <section className="relative grid grid-cols-2 gap-inset-lg mobile:!contents">
          <aside className="flex flex-col mobile:!order-1 desktop:gap-inline-sm">
            <div className="balance-css-wildcard-dashboard rounded-sm bg-neutral-white px-6 py-4 mobile:!rounded-none mobile:!bg-primary-lighter mobile:!px-14 mobile:!pb-inline-xs mobile:!pt-inset-xl">
              <Parcel
                config={() => System.import("@hyperlocal/banking-balance")}
                mountParcel={mountRootParcel}
              />
            </div>

            <ProtectedComponent
              allowedRoles={["Proprietário", "Administrador", "Moderador"]}
            >
              <div className="pix-css-wildcard-dashboard rounded-sm bg-neutral-white px-6 py-4 mobile:!hidden">
                <Parcel
                  config={() => System.import("@hyperlocal/banking-pix")}
                  mountParcel={mountRootParcel}
                />
              </div>
            </ProtectedComponent>
            <div className="mobile:!hidden">
              <Chart />
            </div>
          </aside>

          <aside className="absolute inset-0 col-start-2 h-full w-full overflow-hidden mobile:!contents">
            <div className="statements-css-wildcard-dashboard flex h-full flex-col gap-6 rounded-sm bg-neutral-white mobile:!relative mobile:!order-3 mobile:!overflow-hidden mobile:!bg-transparent mobile:!p-0">
              <Parcel
                config={() => System.import("@hyperlocal/banking-statements")}
                mountParcel={mountRootParcel}
                wrapStyle={{
                  height: "100%",
                  overflow: "hidden",
                }}
              />
            </div>
          </aside>
        </section>
      </DashboardContainer>

      <div id="modal-root" className="absolute z-[99999]"></div>
      <WarningToast />
      <TrustedDeviceWarning />
    </div>
  );
}
