import { useState } from "react";
import {
  ApproveVariables,
  onMutatePendingActions,
  useApproveTransactions,
  useReproveTransactions,
} from "@/api/pending-transactions";
import { MFAValidation } from "@/pages/mfa-validation";
import {
  getDashboardRoutesData,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Dialog, Sheet, toast } from "@hyperlocal/vital2";
import { useNavigate, useSearchParams } from "react-router-dom";

const routes = getDashboardRoutesData();

const getReproveText = (length: number) => {
  return length > 1
    ? "Esses lançamentos estão pendentes. Se forem cancelados, não serão processados. Tem certeza de que deseja cancelar?"
    : "Este lançamento está pendente. Ao ser cancelado, ele não será processado. Tem certeza de que deseja cancelar?";
};

const getApproveText = (length: number) => {
  return length > 1
    ? "Estes lançamentos estão pendentes. Ao aprovar, as transações serão efetuadas. Tem certeza de que deseja aprovar?"
    : "Este lançamento está pendente. Ao aprovar, a transação será efetuada. Tem certeza de que deseja aprovar?";
};

type TransactionAction = "approve" | "reprove";

export const TransactionActionDialog = () => {
  const [open, setOpen] = useState(false);
  const [transactionAction, setTransactionAction] =
    useState<TransactionAction>("approve");

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const selectedTransactionsId = searchParams.getAll("transaction") || [];
  const hasTransactions = selectedTransactionsId.length > 0;

  const handleActionSuccess = () => {
    setSearchParams((params) => {
      params.delete("transaction");
      return params;
    });

    setOpen(false);

    navigate(routes.pendingTransactions);
  };

  const approveMutation = useApproveTransactions({
    onSuccess: async (_, variables) => {
      await onMutatePendingActions(variables);

      handleActionSuccess();
      toast({
        title: "Lançamentos aprovados com sucesso.",
        variant: "success",
      });
    },
  });

  const reproveMutation = useReproveTransactions({
    onSuccess: async (_, variables) => {
      await onMutatePendingActions(variables);

      handleActionSuccess();
      toast({
        title: "Lançamentos cancelados",
        variant: "success",
      });
    },
  });

  const isLoading = approveMutation.isPending || reproveMutation.isPending;

  const description: Record<TransactionAction, string> = {
    approve: getApproveText(selectedTransactionsId.length),
    reprove: getReproveText(selectedTransactionsId.length),
  };

  const actionButton: Record<TransactionAction, JSX.Element> = {
    approve: <Button.Root>Aprovar</Button.Root>,
    reprove: (
      <Button.Root className="!border-status-error-default !bg-status-error-default hover:!border-status-error-dark hover:!bg-status-error-dark">
        Cancelar
      </Button.Root>
    ),
  };

  const handleMFAAction = async (code: string) => {
    const actionBody: ApproveVariables["body"] = {
      Code: code,
      Items: selectedTransactionsId.map((TransactionId) => ({ TransactionId })),
    };

    const actionMap: Record<TransactionAction, () => Promise<void>> = {
      approve: async () =>
        await approveMutation.mutateAsync({
          body: actionBody,
        }),
      reprove: async () => reproveMutation.mutateAsync({ body: actionBody }),
    };

    try {
      await actionMap[transactionAction]();
    } catch (_) {
    } finally {
      setOpen(false);
    }
  };

  const handleOpenChange = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <div className="flex flex-col space-y-2">
          <Button.Root
            disabled={!hasTransactions}
            onClick={() => setTransactionAction("approve")}
          >
            Aprovar
          </Button.Root>
          <Button.Root
            variant="secondary"
            className={
              hasTransactions &&
              "border-status-error-default text-status-error-default hover:border-status-error-dark hover:text-status-error-dark"
            }
            disabled={!hasTransactions}
            onClick={() => setTransactionAction("reprove")}
          >
            Cancelar lançamento
          </Button.Root>
        </div>
      </Dialog.Trigger>
      <Dialog.Content className="w-[372px]">
        <Dialog.Header>
          <Dialog.Title>Atenção!</Dialog.Title>
        </Dialog.Header>
        <p className="text-neutral-darkest">{description[transactionAction]}</p>
        <Dialog.Footer className="grid grid-cols-2 gap-1">
          <Dialog.Close asChild>
            <Button.Root variant="secondary">Voltar</Button.Root>
          </Dialog.Close>
          <Sheet.Root>
            <Sheet.Trigger asChild>
              {actionButton[transactionAction]}
            </Sheet.Trigger>
            <Sheet.Content
              className="p-0"
              onEscapeKeyDown={(e) => {
                if (isLoading) e.preventDefault();
              }}
              onPointerDownOutside={(e) => {
                if (isLoading) e.preventDefault();
              }}
            >
              <MFAValidation
                onSuccess={handleMFAAction}
                isLoading={isLoading}
              />
            </Sheet.Content>
          </Sheet.Root>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};
