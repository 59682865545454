import { DropdownMenu } from "@/components/dropdown-menu";
import { Device } from "@/utils/utility";
import { Block, CheckCircle, MenuKebab, Trash } from "../../svgs";
import { DeviceStatus } from "./use-device-management";

interface UpdateStatusParams {
  deviceId: string;
  status: DeviceStatus;
}

export const DeviceActions = ({
  device,
  onUpdateStatus,
}: {
  device: Device;
  onUpdateStatus: (params: UpdateStatusParams) => Promise<void>;
}) => {
  const isActive = device.deviceStatus === "active";

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        asChild
        disabled={device.deviceStatus === "deleted"}
      >
        <button
          type="button"
          className="cursor-pointer rounded p-2 hover:bg-neutral-lighter disabled:cursor-not-allowed disabled:opacity-30"
        >
          <MenuKebab className="h-5 w-5" />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="min-w-32 overflow-hidden rounded-sm border bg-neutral-white shadow-lower"
          align="end"
          sideOffset={5}
        >
          <DropdownMenu.Item
            className="flex cursor-pointer select-none items-center gap-2.5 border px-4 py-2 text-xs font-medium text-neutral-darkest outline-none hover:bg-neutral-lighter"
            onClick={() =>
              onUpdateStatus({
                deviceId: device.deviceId,
                status: isActive ? "blocked" : "active",
              })
            }
          >
            {isActive ? <Block /> : <CheckCircle />}
            {isActive ? "Bloquear" : "Ativar"}
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.Item
            className="flex cursor-pointer select-none gap-2.5 border px-4 py-2 text-xs/md font-medium"
            onClick={() =>
              onUpdateStatus({
                deviceId: device.deviceId,
                status: "deleted",
              })
            }
          >
            <Trash />
            Excluir
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
};
