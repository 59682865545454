import { Device } from "@/utils/utility";
import { Chips } from "@hyperlocal/vital";
import { DevicesTable } from "./devices-table";
import { EmptyFallback } from "./empty-fallback";
import { LoadingSkeleton } from "./loading-skeleton";
import { Pagination } from "./pagination";

interface DesktopViewProps {
  devices: Device[];
  isLoading: boolean;
  currentPage: number;
  pageCount: number;
  showDeleted: boolean;
  handleUpdateDeviceStatus: (params: {
    deviceId: string;
    status: "active" | "blocked" | "deleted";
  }) => Promise<void>;
  handlePageChange: (page: number) => void;
  toggleDeletedStatus: () => void;
  handleResetFilters: () => void;
}

export function DesktopView({
  devices,
  isLoading,
  currentPage,
  pageCount,
  showDeleted,
  handleUpdateDeviceStatus,
  handlePageChange,
  toggleDeletedStatus,
  handleResetFilters,
}: Readonly<DesktopViewProps>) {
  return (
    <div className="flex h-full flex-col">
      <Chips
        active={showDeleted}
        className="w-fit"
        onClick={toggleDeletedStatus}
      >
        Visualizar dispositivos excluídos
      </Chips>
      <div className="-mr-6 mt-2 flex flex-1 flex-col overflow-hidden lg:justify-between mobile:!mr-0">
        <div className="flex w-full items-center justify-center pr-6 mobile:!pr-0">
          {isLoading && <LoadingSkeleton />}
          {!isLoading && !devices?.length && (
            <EmptyFallback
              hasFilter={showDeleted}
              resetFilters={handleResetFilters}
            />
          )}
          {!isLoading && devices?.length > 0 && (
            <DevicesTable
              devices={devices}
              updateDeviceStatus={handleUpdateDeviceStatus}
            />
          )}
        </div>
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
