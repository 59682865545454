import {
  GetExtractParams,
  GetExtractResponse,
} from "@/services/types/get-extract";
import {
  GetPendingTransactionByIdResponse,
  GetPendingTransactionsParams,
  GetPendingTransactionsResponse,
} from "@/services/types/get-pending-transactions";
import { GetSalesParams, SalesListResponse } from "@/services/types/get-sales";
import { GetTotal } from "@/services/types/get-total";
import { GetWeekChart } from "@/services/types/get-week-chart";
import { API, Types } from "@/utils";
import { isAxiosError } from "axios";
import * as T from "./types";

const apis = {
  GetLogin: async (payload) =>
    await API.login.post<Types["ILogin"]>(`auth/login`, payload),
  GetChartData: async ({ accountId }: T.GetChartDataRequest) => {
    try {
      const response = await API.statements.get<T.GetChartDataResponse>(
        `/Extract/Week?accountId=${accountId}`,
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },

  GenerateLinkOnboard: async (accountId: string) =>
    await API.manager.get<T.GenerateLinkOnboard>(
      `fraud/${accountId}/GenerateLinkOnboard`,
    ),

  getAnticipationData: async (guidCustomer: string) => {
    const { data } = await API.manager.get<T.AnticipationData>(
      `/Sales/${guidCustomer}/Receivables/FutureAgenda`,
    );

    return data;
  },

  getAnticipationAmountByDate: async (
    guidCustomer: string,
    range: {
      minDate: string;
      maxDate: string;
    },
  ) => {
    const { data } = await API.manager.get<T.AnticipationValueByDate>(
      `/Sales/${guidCustomer}/Receipts/Items/date`,
      {
        params: {
          startDate: range.minDate,
          endDate: range.maxDate,
        },
      },
    );

    return data;
  },

  getWeekChart: async (guidCustomer: string) => {
    const { data } = await API.manager.get<GetWeekChart>(
      `/Sales/${guidCustomer}/Receivables/WeekChart`,
    );

    return data;
  },

  getExtract: async (params: GetExtractParams) =>
    await API.statements.get<GetExtractResponse>("/Extract", { params }),

  getPendingTransactions: async (params?: GetPendingTransactionsParams) => {
    const { data } = await API.pendencyList.get<GetPendingTransactionsResponse>(
      "/PendenciesAll",
      { params },
    );

    return data;
  },

  getPendingTransactionById: async (id: string) => {
    const { data } =
      await API.pendencyList.get<GetPendingTransactionByIdResponse>(
        `/ById/${id}`,
      );
    return data;
  },

  manageTransactions: async ({ body, variant }: T.ManageTransactionRequest) => {
    const url = variant === "APPROVE" ? "/Approve" : "/Reprove";
    const defaultError =
      variant === "APPROVE"
        ? "Não foi possível aprovar os lançamentos pendentes, tente novamente mais tarde."
        : "Não foi possível cancelar os lançamentos pendentes, tente novamente mais tarde.";

    try {
      const { data } = await API.pendencyList.post(url, body);
      return data;
    } catch (error) {
      const getMessage = () => {
        if (isAxiosError(error)) {
          const errorMessage =
            error.response?.data?.ErrorMessages?.[0] || defaultError;

          return errorMessage;
        }

        return defaultError;
      };

      throw new Error(getMessage());
    }
  },

  getTotal: async () => {
    const { data } = await API.pendencyList.get<GetTotal>("/Totals");
    return data;
  },

  getSales: async ({
    endDate,
    guidAccount,
    startDate,
  }: GetSalesParams): Promise<SalesListResponse> => {
    const params = new URLSearchParams();

    params.append("startDate", startDate);
    params.append("endDate", endDate);

    try {
      const response = await API.manager.get(`sales/${guidAccount}/list`, {
        params,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default apis;
