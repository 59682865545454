import { Button, Dialog } from "@hyperlocal/vital2";
import { Content } from "./utils";

type TrustedDeviceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  content: Content;
};

export const TrustedDeviceModal = ({
  onClose,
  isOpen,
  content,
}: TrustedDeviceModalProps) => (
  <Dialog.Root open={isOpen} onOpenChange={onClose}>
    <Dialog.Content className="!max-w-[372px]">
      <Dialog.Header>
        <Dialog.Title>{content.title}</Dialog.Title>
      </Dialog.Header>
      <div>{content.body}</div>
      <Dialog.Footer className="!justify-center">
        <Button.Root
          variant="secondary"
          className="w-full"
          type="button"
          onClick={() => {
            content.actions.cancel.action();
          }}
        >
          {content.actions.cancel.label}
        </Button.Root>
        <Button.Root
          type="button"
          variant="primary"
          className="w-full"
          onClick={content.actions.confirm.action}
        >
          {content.actions.confirm.label}
        </Button.Root>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Root>
);
