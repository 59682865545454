import { useEffect, useState } from "react";
import { TextField } from "@hyperlocal/vital2";
import { ArrowLeft, ArrowRight } from "../../svgs";

export type PaginationProps = {
  currentPage: number;
  pageCount: number;
  isLoading: boolean;
  onPageChange: (page: number) => void;
};

export function Pagination({
  currentPage,
  pageCount,
  isLoading,
  onPageChange,
}: Readonly<PaginationProps>) {
  const [inputValue, setInputValue] = useState(currentPage);

  useEffect(() => {
    setInputValue(currentPage);
  }, [currentPage]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber;
    if (!value) return;
    setInputValue(value);
  };

  const handleInputBlur = () => {
    let pageNumber = Number(inputValue);

    if (pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > pageCount) {
      pageNumber = pageCount;
    }

    setInputValue(currentPage);
    onPageChange(pageNumber);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleInputBlur();
    }
  };

  return (
    <div className="flex items-center justify-end gap-2 py-3 pr-6 text-neutral-darker">
      <button
        data-testid="pagination-left-button"
        className="rounded-xs text-neutral-darkest hover:enabled:bg-neutral-lighter disabled:opacity-30"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage <= 1 || isLoading}
      >
        <ArrowLeft />
      </button>

      <TextField.Input
        data-testid="page-number-input"
        className="h-6 w-[38px] !p-0 text-center disabled:text-neutral-light"
        type="number"
        min="1"
        max={pageCount}
        value={inputValue}
        disabled={isLoading}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
      />

      <span>de</span>
      <span className="w-8 text-center">{isLoading ? "-" : pageCount}</span>

      <button
        data-testid="pagination-right-button"
        className="rounded-xs text-neutral-darkest hover:enabled:bg-neutral-lighter disabled:opacity-30"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= pageCount || isLoading}
      >
        <ArrowRight />
      </button>
    </div>
  );
}
