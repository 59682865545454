import {
  Id,
  TeamAccessResponse,
  TeamListItem,
} from "@/pages/settings/react-query/team-access-list/use-team-access-list.types";
import { Role } from "@/pages/settings/sections/team-access/new-access/components/new-user-form";
import { API, queries, toast, useAccountStore } from "@/utils";
import { useQuery } from "@tanstack/react-query";

const { teamAccessKeys } = queries;

const mappedRole: Record<Id, Role> = {
  "d34f9539-283e-48b8-8c5c-095a5fa4d250": "Moderador",
  "a97f39d8-6c4e-466a-860c-93aa6bb15052": "Administrador",
  "50f930fb-91f5-4b13-8c39-256a6fb006d0": "Financeiro",
  "d4f9411d-f07b-4566-8be9-0bc1053b81c0": "Analista",
};

async function getTeamAccessList(
  showDeleted: boolean,
): Promise<TeamListItem[]> {
  try {
    const response = await API.accessControl.get<TeamAccessResponse>(
      "/api/UserManager/All",
      {
        params: {
          deleted: showDeleted,
        },
      },
    );

    return response.data.items.map((item) => ({
      id: item.userManagerId,
      createdAt: "",
      email: item.email,
      name: item.name,
      photoURL: "",
      role: mappedRole[item.accessId],
      status: showDeleted ? "DELETED" : "ACTIVE",
      document: item.cpf,
      phone: item.contact,
    }));
  } catch (error) {
    toast.error("Não foi possível buscar as contestações.");
    throw new Error(error);
  }
}

type UseTeamAccessListProps = {
  deleted: boolean;
};

export function useTeamAccessList({ deleted }: UseTeamAccessListProps) {
  const { currentAccountId } = useAccountStore();

  return useQuery({
    queryKey: teamAccessKeys.list({ accountId: currentAccountId, deleted }),
    queryFn: () => getTeamAccessList(deleted),
    staleTime: 1000 * 60,
  });
}
